.home {
  overflow-x: clip;
}
body {
  overflow-x: hidden;
}
/************NavBar**********************/
.nav_link:hover {
  color: #d6bcfa /*purple.200*/;
}
[data-theme="dark"] .nav_link:hover {
  color: #9f7aea /*purple.400*/;
}

@font-face {
  font-family: "Cosmic";
  src: local("Just-Cosmic"),
    url("./assets/fonts/Just-Cosmic.otf") format("opentype");
  font-weight: bold;
}
.font-face-cosmic {
  font-family: "Cosmic";
  font-size: 45px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /************Dashboard*******************/
  .nube1 {
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
    animation-fill-mode: forwards;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /****************Footer**********************/
  /**********************Soon******************************/
  /*For desktop*/
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /************Dashboard*******************/
  .nube1 {
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /****************Footer**********************/
  /**********************Soon******************************/
  /*For desktop*/
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /************Navbar**********************/

  /************Dashboard*******************/
  .nube1 {
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /****************Footer**********************/
  /*****************Soon***********************/
}
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /************Navbar**********************/
  /************Dashboard*******************/
  .nube1 {
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /****************Footer**********************/
  /*****************Soon***********************/
}
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  /************Dashboard*******************/
  .nube1 {
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /****************Footer**********************/
  /*****************Soon***********************/
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 319px) and (max-width: 480px) {
  /************Navbar**********************/
  /************Dashboard*******************/
  .nube1 {
    position: relative;
    z-index: 100;
    transform: translate(0, 0);
    animation: move 45s linear infinite;
    animation-name: move1;
  }
  @keyframes move1 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(300%, 0);
    }
  }
  .nube2 {
    transform: translate(0, 0);
    animation: move 60s linear infinite;
    animation-name: move2;
    position: absolute;
    top: 660px;
  }
  @keyframes move2 {
    0% {
      transform: translate(-300%, 0);
    }
    100% {
      transform: translate(380%, 0);
    }
  }

  /************ ImageSlide Css ************/
  .imageslide-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .imageslide-slider .item img {
    width: 80%;
    margin: 0 auto 15px auto;
    border: solid black 2px;
  }
  /****************Lore************************/
  /**********************Deck******************/
  .Discover {
    grid-gap: 60%;
  }
  /****************Footer**********************/
}
/**********************Soon******************/
/**********************Deck******************/
html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
/********Features****************/
/********Dashboard***************/
/********Constellations**********/
.-appear {
  animation: appear 3s linear;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
